import { Document, PDFViewer, Page, View, Text } from "@react-pdf/renderer";
import React from "react";
import { TitleLeft, TitleRight } from "../Common/Components/title";
import { monthArr } from "../Common/utils";
import { styles } from "../Common/style";
import { Footer, RenderSigns } from "../Common/Components/footer";
import TextWithCheckBox from "../Common/Components/textWithCheckBox";

export default function PhieuYeuCauKiemNghiem(props) {
  //Ngang
  const { currentDate, code, getInfo } = props;
  return (
    <PDFViewer style={{ width: "100%", height: "calc(100% - 40px)" }}>
      <Document>
        <Page size="A4" style={[styles.page1Cm]} orientation={"portrait"}>
          <View style={styles.section}>
            <View style={styles.row}>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleLeft />
              </View>
              <View style={[styles.col_50, styles.textCenter]}>
                <TitleRight />
                {/* <Text
                  style={[
                    styles.italic,
                    { margin: "5px", marginRight: "50px", textAlign: "right" },
                  ]}
                >
                  Yên Bái, ngày {currentDate.getDate()} tháng{" "}
                  {monthArr[currentDate.getMonth()]} năm{" "}
                  {currentDate.getFullYear()}
                </Text> */}
              </View>
            </View>

            <View style={{ margin: "30px" }}>
              <Text style={[styles.bold, styles.textCenter, styles.fS16]}>
                PHIẾU YÊU CẦU KIỂM NGHIỆM
              </Text>
              <View style={[{ marginLeft: "75%" }]}>
                {/* <Text>Số ĐKKN: {getInfo("registrationNumber")}</Text> */}
                <Text>Số ĐKKN: .....</Text>
                <Text>
                  Số: {code ? code + "/" + currentDate.getFullYear() : ""}
                </Text>
              </View>
            </View>

            <Information {...props} />
            <View style={{ marginBottom: "5px" }}></View>
            {/* <PdfTable {...props} /> */}
            <View style={{ marginBottom: "5px" }}></View>
            <Information2 {...props} />
          </View>
          {/*  */}
          <Footer
            ngayBanHanh="19/01/2022"
            overrideCode="Ký hiệu: KNYB/BM.7.1.01.03"
            {...props}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
}

function Information(props) {
  const { data, getInfo, currentSubStanceType } = props;

  const isDuocLieu =
    currentSubStanceType.toLowerCase() === "Thuốc/Cổ Truyền".toLowerCase();

  const correctTexts = (list, prefix = ", ") => {
    return list.filter((x) => !!x).join(prefix);
  };

  const isSamplerRequiredBy = (input) => {
    return (
      getInfo("samplerRequired").toString().toLowerCase() ===
      input.toLowerCase()
    );
  };

  const targets = (getInfo("targetInformation") || []).map((x) => x.targetName);

  const substanceInformation = [
    ...new Set(
      data
        .flatMap((x) => x.substanceInformation)
        .filter((x) => x?.substanceName)
        .map((x) => {
          return `${x.substanceName} ${x.substanceContent}${x.substanceUnit}`;
        })
    ),
  ].join(", ");

  const sampleName = `${getInfo("dosageForm")} ${getInfo(
    "sampleName"
  )}(${substanceInformation})`;

  return (
    <View>
      <Text>
        Tên, địa chỉ cơ sở gửi mẫu:{" "}
        {correctTexts([
          getInfo("locationName"),
          getInfo("pickingAddress"),
          getInfo("commune"),
          getInfo("ward"),
          getInfo("province"),
        ])}
      </Text>
      <View style={styles.row}>
        <Text style={[styles.col_50]}>
          Điện thoại: {getInfo("pickingPhone")}
        </Text>
        <Text style={[styles.col_50]}>Fax: {""}</Text>
      </View>
      <Text>Tên mẫu để kiểm nghiệm: {sampleName}</Text>

      <Text>
        Tên, địa chỉ cơ sở sản xuất:{" "}
        {correctTexts([getInfo("producerName"), getInfo("producerAddress")])}
      </Text>
      <Text>
        Tên, địa chỉ doanh nghiệp nhập khẩu hoặc nhà phân phối (nếu
        có):..........................................................
        .....................................................................................................................................................................
      </Text>
      <View style={styles.row}>
        <Text style={styles.col_33}>
          Số lô: {getInfo("chronicleBatchDate")}
        </Text>
        <Text style={styles.col_33}>
          Ngày sản xuất: {getInfo("productionDate")}
        </Text>
        <Text style={styles.col_33}>
          Hạn sử dụng: {getInfo("expirationDate")}
        </Text>
      </View>
      <Text>
        Số đăng ký lưu hành hoặc số giấy phép nhập khẩu:{" "}
        {correctTexts(
          [getInfo("registerNumber"), getInfo("importLicense")],
          "/ "
        )}
      </Text>
      <View style={styles.bold}>
        <Text style={styles.bold}>Yêu cầu KN</Text>
        <View style={[{ marginLeft: "1cm", width: "6cm" }]}>
          <TextWithCheckBox
            checked={isSamplerRequiredBy("Kiểm tra chất lượng")}
            text="• Kiểm tra chất lượng"
          />
          <TextWithCheckBox
            checked={isSamplerRequiredBy("Phân tích kết quả")}
            text="• Phân tích kết quả"
          />
        </View>
      </View>

      <Text style={styles.bold_italic}>
        Tích vào các chỉ tiêu cần phân tích/kiểm tra chất lượng.
      </Text>
      <Text style={styles.bold}>Thuốc thành phẩm, Mỹ phẩm.</Text>
      <ListCheckBoxes
        targets={targets}
        list={[
          "Hình thức, Tính chất",
          "Độ đồng đều khối lượng",
          "Thể tích",
          "Độ hòa tan /Độ rã",
          "Định tính",
          "Định lượng",
          "Độ đồng đều hàm lượng",
          "Độ ẩm",
          "Độ vô trùng",
          "Giới hạn nhiễm khuẩn",
          "Chất bảo quản (nếu có)",
          "Độ trong, độ đồng nhất",
          "Tỷ trọng",
          "Tạp chất (nếu có)",
          ".......................................",
          ".......................................",
          ".......................................",
          "Các chỉ tiêu theo",
        ]}
        allowCheck={!isDuocLieu}
      />

      <Text style={styles.bold}>Dược liệu và vị thuốc y học cổ truyền</Text>
      <ListCheckBoxes
        targets={targets}
        list={[
          "Mô tả",
          "Bột",
          "Độ ẩm",
          "Định tính",
          "Định lượng",
          "Tạp chất",
          "Tỷ lệ vụn nát",
          "Chất chiết trong dược liệu",
          "Tro không tan trong acid",
          "Tro toàn phần",
          ".........................................",
          ".........................................",
          ".........................................",
          ".........................................",
          "Các chỉ tiêu theo",
        ]}
        allowCheck={isDuocLieu}
      />
    </View>
  );
}

function Information2(props) {
  const { data, currentSubStanceType, currentDate, getInfo } = props;
  const info = data[0] || {};
  let signs = ["Người lấy mẫu", "Người nhận mẫu"];

  const isSubcontractors = getInfo("catalog") === "Có";
  const isKHNhanTrucTiep =
    getInfo("z3").toString().toLowerCase() ===
    "Khách hàng nhận trực tiếp".toLowerCase();
  const isGuiBuuDien =
    getInfo("z3").toString().toLowerCase() === "Gửi bưu điện".toLowerCase();

  const isKNqd =
    getInfo("testMethod").toString().toLowerCase() ===
    "Do TTKN quyết định".toLowerCase();
  const isKHqd =
    getInfo("testMethod").toString().toLowerCase() ===
    "do khách hàng yêu cầu".toLowerCase();

  const isTCCS =
    getInfo("z4").toString().toLowerCase() === "TCCS".toLowerCase();
  const isDDVN =
    getInfo("z4").toString().toLowerCase() === "DĐVN".toLowerCase();

  const getShortDate = (d) => {
    const date = new Date(d);
    if (date instanceof Date && !isNaN(date)) {
      return `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear().toString().substr(-2)}`;
    }

    return undefined;
  };

  return (
    <>
      {/* footer info */}
      <View>
        <View style={styles.row}>
          <Text>Tiêu chuẩn áp dụng: </Text>
          <TextWithCheckBox checked={isTCCS} text="TCCS" />
          <TextWithCheckBox checked={isDDVN} text="DĐVN" />
          <TextWithCheckBox text="Tiêu chuẩn khác" />
          <Text>.......................................................</Text>
        </View>
        <View style={styles.row}>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isKNqd}
              text="Phương pháp thử: "
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isKHqd}
              text="Do TTKN quyết định"
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <Text>Do khách yêu cầu</Text>
          </View>
        </View>
        <Text>
          Thời điểm gửi mẫu: {currentDate.getHours()} giờ{" "}
          {currentDate.getMinutes()} phút, ngày {currentDate.getDate()} tháng{" "}
          {monthArr[currentDate.getMonth()]} năm {currentDate.getFullYear()}.
          Thời gian trả kết quả: {getShortDate(getInfo("appointmentDate"))}
        </Text>
        <Text>Tình trạng mẫu khi gửi: {getInfo("si1")}</Text>
        <Text>Số lượng mẫu gửi: {getInfo("totalSample")}</Text>
        <Text>Điều kiện bảo quản của mẫu: {getInfo("si2")}</Text>
        <View style={styles.row}>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isKHNhanTrucTiep}
              text="Hình thức trả kết quả: "
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isGuiBuuDien}
              text="Khách hàng nhận trực tiếp"
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <Text>Gửi bưu điện</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View
            style={{
              flex: "0 0 46%",
            }}
          >
            <TextWithCheckBox
              checked={isSubcontractors}
              text="Đồng ý khi TTKN sử dụng Nhà thầu phụ: "
            ></TextWithCheckBox>
          </View>
          <View
            style={{
              flex: "0 0 45%",
            }}
          >
            <TextWithCheckBox
              checked={!isSubcontractors}
              text="Có (Nhà thầu phụ do TTKN quyết định)"
            ></TextWithCheckBox>
          </View>
          <View
            style={{
              flex: "0 0 9%",
            }}
          >
            <Text>Không</Text>
          </View>
        </View>

        <Text>
          Phí kiểm nghiệm: Theo Quyết định số 2772/QĐ-UBND ngày 06/12/2021 về
          việc ban hành đơn giá dịch vụ Kiểm nghiệm thuốc, mỹ phẩm, an toàn thực
          phẩm, sinh phẩm y tế sử dụng ngân sách nhà nước trên địa bàn tỉnh Yên
          Bái.
        </Text>
        <Text style={[styles.bold, { margin: "5px 0 5px 0" }]}>
          • Cam kết của Trung tâm Kiểm nghiệm thuốc, mỹ phẩm, thực phẩm tỉnh Yên
          Bái
        </Text>
        <Text>
          Trung tâm kiểm nghiệm cam kết giữ bí mật các thông tin liên quan đến
          khách hàng và các kết quả thử nghiệm liên quan đến mẫu gửi của khách
          hàng (ngoại trừ trường hợp khi pháp luật yêu cầu).
        </Text>
        <Text style={[styles.bold, { margin: "5px 0 5px 0" }]}>
          • Cam kết của khách hàng gửi mẫu yêu cầu kiểm nghiệm:
        </Text>
        <Text>
          Tự chịu trách nhiệm về toàn bộ thông tin cung cấp trên phiếu yêu cầu
          kiểm nghiệm.
        </Text>

        <Text
          style={[styles.bold_italic, styles.underline, { marginLeft: "0" }]}
        >
          Ghi chú:
        </Text>
        <View style={styles.italic}>
          <Text>
            - Trung tâm chỉ giải quyết các phàn nàn trong thời hạn 07 ngày từ
            khi trả kết quả.
          </Text>
          <Text>
            - Đối với các mẫu do khách hàng mang đến, trung tâm cam kết chịu
            trách nhiệm về kết quả trên mẫu thử, không chịu trách nhiệm về nguồn
            gốc xuất xứ mẫu.
          </Text>
        </View>
      </View>
      {/* footer signs */}
      <Text style={[styles.textRight, { marginRight: "1cm" }]}>
        ngày {currentDate.getDate()} tháng {monthArr[currentDate.getMonth()]}{" "}
        năm {currentDate.getFullYear()}
      </Text>
      <RenderSigns titles={signs} />
    </>
  );
}

function ListCheckBoxes({ list, targets, allowCheck }) {
  const numRow = Math.ceil(list.length / 3);
  const lasted = (index) => index + numRow * 2;

  const isChecked = (input) => {
    const normalize = (str) => str.replace("(nếu có)", "").trim().toLowerCase();
    const searches = input.split("/").map(normalize);
    return (
      allowCheck && targets.some((item) => searches.includes(normalize(item)))
    );
  };

  return (
    <View>
      {[...Array(numRow)].map((_, index) => (
        <View style={styles.row}>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isChecked(list[index])}
              text={`${index + 1}. ${list[index]}`}
            ></TextWithCheckBox>
          </View>
          <View style={styles.col_33}>
            <TextWithCheckBox
              checked={isChecked(list[index + numRow])}
              text={`${index + numRow + 1}. ${list[index + numRow]}`}
            ></TextWithCheckBox>
          </View>
          {list.length > lasted(index) && (
            <View style={styles.col_33}>
              <TextWithCheckBox
                checked={isChecked(list[lasted(index)])}
                text={`${lasted(index) + 1}. ${list[lasted(index)]}`}
              ></TextWithCheckBox>
            </View>
          )}
        </View>
      ))}
    </View>
  );
}
